<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
      >
        <div>
          <procedure-detail-header
            v-if="loggedUser.role === 'professional'"
            no-back-button
            no-survey-creation-button
            :no-document-creation-button="!canHandleResource"
            :no-resource-creation-button="!canHandleResource"
            download-entire-procedure
            show-reload-resource
            :procedure-num-files="procedureData.document_files_count"
            :no-resource-found="!pluginSettings.resourceFound"
            :loading-create-resource="loading.createResource"
            :loading-download-entire-procedure="loading.downloadEntireProcedure"
            :loading-reload-resource="loading.reloadResource"
            @clickCreateDocument="openDocumentModal"
            @clickCreateFolder="openFolderModal"
            @clickCreateResource="createResource"
            @clickDownloadEntireProcedure="downloadEntireProcedure(procedureData.title+'.zip')"
            @clickReloadResource="reloadResource"
          />

          <procedure-card
            :procedure-obj="procedureData"
            :procedure-enter-btn="false"
            :redirect-to-detail="true"
            no-title-block
            no-subtitle-block
            no-status-icon
            no-survey-icon
            no-procedure-update
            no-invite-professional
            no-procedure-archive
            header-small
          />

          <v-divider></v-divider>
          <!-- FOLDERS -->
          <v-row class="mt-3">
            <v-col
              v-for="(folder,idx) in level0Folders"
              :key="'F'+idx"
              cols="12"
              sm="12"
              md="3"
            >
              <folder-card
                folder-route="pluginProcedureFolderView"
                :folder-procedure-id="procedureData.ext_id"
                :folder-obj="folder"
                :folder-permissions="folder.folder_permissions"
                :go-to-folder-target-id="procedureData.ext_id"
                :api-target-id="procedureData.ext_id"
                :api-target-perm-id="procedureData.id"
                :api-routes="apiRoutes"
                @reloadData="reloadData"
                @droppedItem="setIsDropped"
              />
            </v-col>
          </v-row>

          <!--- DOCUMENTS -->
          <v-row>
            <v-col
              v-for="(doc,idx) in level0Documents"
              :key="'D'+idx"
              cols="12"
              sm="12"
              md="4"
            >
              <document-card
                is-plugin-version
                is-draggable
                no-priority
                no-action
                no-status
                document-route="pluginProcedureDocumentsView"
                :can-handle-resource="canHandleResource"
                :document-obj="doc"
                :procedure-id="procedureData.ext_id"
                :is-dropped="isDropped[doc.id]"
                @reloadData="reloadData"
              />
            </v-col>
          </v-row>

          <create-folder
            v-if="isPageFullyLoaded"
            is-plugin-version
            :is-show="isShow.folderCreate"
            @close="isShow.folderCreate=false"
            @reload-data="reloadData"
          />
          <create-document
            v-if="isPageFullyLoaded"
            is-plugin-version
            no-priority
            no-action
            no-status
            :is-show="isShow.docCreate"
            @close="isShow.docCreate=false"
            @reload-data="reloadData"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProcedureDetailHeader from '@/components/section-headers/procedure-detail-header.vue'
import ProcedureCard from '@/components/cards/procedure-card.vue'
import DocumentCard from '@/components/cards/document-card.vue'
import CreateDocument from '@/components/modals/create-document.vue'
import CreateFolder from '@/components/modals/create-folder.vue'

import { mapGetters } from 'vuex'
import Api from '@/plugins/api'
import axios from 'axios'
import FolderCard from '@/components/cards/folder-card.vue'

export default {
  name: 'PluginProcedure',
  components: {
    FolderCard,
    CreateFolder,
    ProcedureDetailHeader,
    ProcedureCard,
    CreateDocument,
    DocumentCard,
  },
  data() {
    return {
      isDropped: {},
      isPageFullyLoaded: false,
      isShow: {
        docCreate: false,
        folderCreate: false,
      },
      loading: {
        createResource: false,
        reloadResource: false,
        downloadEntireProcedure: false,
      },
      procedureData: {},
      mapExpirationChip: {
        expired: 'Scaduta',
      },
      pluginSettings: {
        resourceFound: null,
      },
      isTheCreator: false,
      externalRights: false,
      apiRoutes: {
        editApiMethod: 'editPluginFolder',
        deleteApiMethod: 'destroyPluginFolder',
        editDocumentApiMethod: 'editPluginDocument',
        permGetApiMethod: 'getFolderPermissions',
        permSetApiMethod: 'setFolderPermissions',
        permEditApiMethod: 'updateFolderPermission',
        permDeleteApiMethod: 'deleteFolderPermission',
      },
      selectedCard: null,
    }
  },
  computed: {
    ...mapGetters(['token', 'loggedUser', 'procedureList']),
    isOwner() {
      return this.loggedUser.company_role === 'owner'
    },
    isAdmin() {
      return this.loggedUser.company_role === 'admin'
    },
    canHandleResource() {
      //return this.isOwner || this.isAdmin || this.isTheCreator
      return true
    },
    level0Folders() {
      if (this.procedureData.folders) {
        return this.procedureData.folders.filter(item => item.parent_id === 0)
      }

      return []
    },
    level0Documents() {
      if (this.procedureData.documents) {
        return this.procedureData.documents.filter(item => item.folder_id === 0)
      }

      return []
    },
  },
  watch: {
    procedureList() {
      this.getData()
    },
  },
  async created() {
    // if the user token is not set in the vuex store, set it. Also reset if the token is different from the one in the url
    if (this.$route.query.token && (!this.token || this.token !== this.$route.query.token)) {
      await this.$store.commit('resetStore')

      // dispatch loginToken action and pass the token as a parameter. The token can be retrieved from the url
      await this.$store.dispatch('loginToken', this.$route.query.token)
    }

    if (this.$route.query.rw) {
      this.externalRights = true
    }

    // check if user exists as a key in the vuex store (if not, it means that the user is not logged in)
    // check also if this.loggedUser is an empty array
    if (!this.loggedUser || !Object.keys(this.loggedUser).length) {
      this.loading.reloadResource = true
      await this.$store.dispatch('getPartnerSettings')
        .then(() => this.$store.dispatch('getPluginCompanySettings'))
        .then(() => this.$store.dispatch('getPluginUserInfoAll'))
        .then(() => this.$store.dispatch('getLicense'))
        .then(() => this.$store.dispatch('getPluginProcedure', parseInt(this.$route.params.id, 10)))
        .then(() => {
          if (this.procedureList.some(item => item.ext_id === parseInt(this.$route.params.id, 10))) {
            this.getData()
            this.setResourceFound(true)
            this.setPageFullyLoaded()
            this.loading.reloadResource = false
          } else {
            this.setResourceFound(false)
            this.setPageFullyLoaded()
            this.loading.reloadResource = false
          }
        })
        .catch(error => {
          this.loading.reloadResource = false
          console.log(error)
          this.setPageFullyLoaded()
        })
    } else {
      await this.setPageFullyLoaded()
    }
  },
  async mounted() {
    // before getting the procedure data, check if the procedure list already contains the procedure data
    if (!this.procedureList.some(item => item.ext_id === parseInt(this.$route.params.id, 10))) {
      if (this.isPageFullyLoaded) {
        try {
          await this.$store.dispatch('getPluginProcedure', parseInt(this.$route.params.id, 10))
          if (this.procedureList.some(item => item.ext_id === parseInt(this.$route.params.id, 10))) {
            await this.getData()
            await this.setResourceFound(true)
          } else {
            await this.setResourceFound(false)
          }
        } catch (error) {
          console.log(error)
        }
      }
    } else {
      await this.getData()
      await this.setResourceFound(true)
    }
  },
  methods: {
    async reloadData() {
      await this.$store.dispatch('getPluginProcedure', this.procedureData.ext_id)
      this.procedureData = await this.procedureList.find(item => item.ext_id === this.procedureData.ext_id)
      this.isDropped = {}
    },
    async getData() {
      if (this.procedureList.length > 0) {
        this.procedureData = await this.procedureList.find(item => item.ext_id === parseInt(this.$route.params.id, 10))
      }
    },
    async setResourceFound(status) {
      this.pluginSettings.resourceFound = status
    },
    async createResource() {
      this.loading.createResource = true
      try {
        const data = {
          title: this.$route.query.reference ?? `Spazio di Archiviazione ${this.$route.params.id}`,
          reference: this.$route.query.reference ?? `Spazio di Archiviazione ${this.$route.params.id}`,
          ext_id: this.$route.params.id,
        }
        const resp = await Api.createPluginProcedure(this.$route.params.id, data)
        if (resp.data.status === 'success') {
          await this.$store.dispatch('getPluginProcedure', this.$route.params.id)
          await this.getData()
          await this.setResourceFound(true)
          this.loading.createResource = false
        } else {
          this.loading.createResource = false
        }
      } catch (error) {
        console.log(error)
      }
    },
    async reloadResource() {
      this.loading.reloadResource = true
      setTimeout(async () => {
        try {
          await this.$store.dispatch('getPartnerSettings')
          await this.$store.dispatch('getPluginProcedure', parseInt(this.$route.params.id, 10))
        } catch (error) {
          if (error.status === 404) {
            await this.setResourceFound(false)
          } else {
            console.log(error)
          }
        }
        this.loading.reloadResource = false
      }, 1000)
    },
    async downloadEntireProcedure(title) {
      this.loading.downloadEntireProcedure = true
      const url = `${this.$apiUrl}ext/procedures/${this.$route.params.id}/documents/download/all`
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then(response => {
          this.forceFileDownload(response, title)
          this.loading.downloadEntireProcedure = false
        })
        .catch(error => {
          switch (error.response.status) {
            case 403:
              this.$store.dispatch('set_alert', { msg: 'Non hai i permessi necessari per scaricare la pratica.', type: 'error' })
              break
            default:
              this.$store.dispatch('set_alert', { msg: 'Errore nel download, si prega di riprovare.', type: 'error' })
              break
          }
          this.loading.downloadEntireProcedure = false
        })
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    async setPageFullyLoaded() {
      this.isPageFullyLoaded = true
    },
    setIsDropped(documentId) {
      this.$set(this.isDropped, documentId, true)
    },
    openFolderModal() {
      this.isShow.folderCreate = true
    },
    openDocumentModal() {
      this.isShow.docCreate = true
    },
  },
}
</script>

<style scoped>
    .container {
        max-width: 90%;
    }
</style>
